body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.herocomponent {
  padding: 5rem 0;
  height: 60vh;
  background-image: url('https://images.unsplash.com/photo-1531747056595-07f6cbbe10ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80');
  background-size: cover;
  background-position: center;
}

.headercontainer {
  background-color: #e6e926;
  padding: 3rem;
  color: white;
  width: 32.5rem;
}

.heading {
  font-size: 5rem;
}

.subheading {
  margin: 1rem 0;
  font-weight: 400;
}

.errormessage {
  color: red;
}
